<template>
  <div class="reward-content-setting-table">
    <section>
      <BaseTable
        :data="displayData"
        testName="rewardDistribution"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="獎勵發放名稱" align="center" />
        <BaseElTableColumn prop="rewardStatus" label="獎項狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="get(scope.row.rewardStatus, 'tagType')">
              {{ get(scope.row.rewardStatus, 'label') }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="enable" label="活動狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="statusTrans(scope.row.status).tagType">
              {{
                statusTrans(scope.row.status).label
              }}
            </Tag>
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn
          prop="startAt"
          label="開始時間"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ dateFormat(scope.row.startAt) }}
            </div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="endAt"
          label="結束時間"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              {{ dateFormat(scope.row.endAt) }}
            </div>
          </template>
        </BaseElTableColumn>

        <BaseElTableColumn
          prop="name"
          label="操作"
          fixed="right"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <TableEditBtnGroup
              :testName="scope.row.name"
              editBtn="獎項管理"
              @edit="manageReward(scope.row)"
              @delete="onRowDelete(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
    </section>
    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteMemberGame"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
// APIs
import { DeleteRewardDistribution } from '@/api/rewardDistribution'
import dayjs from '@/lib/dayjs'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
// Utils
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import { get, map, isEmpty, sortBy, first } from 'lodash'
import { rewardStatusTypeConfig } from '@/config/lottery'
import { toFixed } from '@/utils/number'
import { awardTypeConfig } from '@/config/rewardDistribution'

export default defineComponent({
  name: 'RewardDistributionTable',
  components: {
    DeleteDialog,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const router = useRouter()
    const {
      shopId,
      dateFormat,
    } = useTable()
    const selectRow = ref(null)
    const deleteDialog = ref(false)
    const onRowDelete = (row) => {
      selectRow.value = row
      deleteDialog.value = true
    }
    const statusTrans = computed(() => {
      return (status) => {
        const statusMap = {
          open: { label: '進行中', tagType: 'action' },
          wait: { label: '未開始', tagType: 'info' },
          finish: { label: '已結束', tagType: 'danger' },
        }
        return get(statusMap, status, {})
      }
    })

    const manageReward = (row) => {
      console.log('❓', row)
      router.push({
        name: 'RewardDistributionRewardSetting',
        params: {
          activityId: row.id,
        },
      })
    }
    const displayData = computed(() => {
      return map(props.tableData, (item) => {
        const id = get(item, 'id')
        const name = get(item, 'name')

        let rewardStatus = awardTypeConfig.normal
        const awardError = get(item, 'award.errors[0]')

        if (awardError) rewardStatus = get(awardTypeConfig, awardError?.type)

        const enable = get(item, 'enable')
        const startAt = get(item, 'startAt')
        const endAt = get(item, 'endAt')
        const status = get(item, 'status')
        return {
          id,
          name,
          rewardStatus,
          enable,
          startAt,
          endAt,
          status,
          awardId: get(item.award, 'StandardAward.id'),
        }
      })
    })
    const deleteMemberGame = async () => {
      const [, err] = await DeleteRewardDistribution({
        shopId: shopId.value,
        id: selectRow.value.id,
      })
      if (err) return window.$message.error(err)
      deleteDialog.value = false
      // memberGameList.value = res
      emit('refresh')
    }
    const rounded = (value) => {
      return toFixed(value, 2)
    }
    return {
      displayData,
      deleteMemberGame,
      selectRow,
      dateFormat,
      onRowDelete,
      deleteDialog,
      statusTrans,
      manageReward,
      rounded,
      get,
    }
  },
})
</script>

<style scoped lang="postcss">
.head-block {
  @apply flex items-center justify-between mb-[24px];
}
.test {
  overflow: auto;
}
</style>
